/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Modal {
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: -100%;
  visibility: hidden;
  width: 100%;
  z-index: 999;

  :global {

    .title {
      color: $greyDarkest;
      font-size: $fontSize-sm;
      font-weight: $font-weight-l;
      line-height: 22px;
      margin-bottom: 16px;
      padding: 0 28px;
      text-transform: uppercase;
    }

    .text {
      color: $greyDarkest;
      font-size: $fontSize-sm;
      font-style: normal;
      font-weight: $font-weight-m;
      line-height: 22px;
      padding: 0 0 18px;
    }
  }

  &.Active {
    opacity: 1;
    top: 0;
    visibility: visible;

    .ModalBackdrop {
      opacity: 0.1;
    }

    .ModalContent {
      top: 50%;
    }
  }

  &.ModalSecondary {
    text-align: center;

    .ModalContent {
      max-width: 375px;
      overflow: hidden;
      padding: 12px 16px 24px;
    }

    :global {

      .additionalInfo {
        font-size: $fontSize-s;
        letter-spacing: 0.5px;
        line-height: 20px;
        margin-bottom: 12px;
        position: relative;

        > span {
          padding-left: 20px;
          position: relative;
        }

        svg {
          height: 16px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
        }

        .highlighted {
          color: $purple;
          font-weight: $font-weight-l;
        }
      }

      button, a {
        margin: 0 auto 14px;
        width: 220px;
      }
    }
  }

  &.ModalTertiary {

    .ModalPageContent {
      height: calc(100% - 32px);
    }

    .ModalContent {
      max-height: 100%;
      max-width: 1060px;
      width: calc(100% - 32px);
    }
  }

  &.ModalWrapContent {

    .ModalPageContent {
      height: auto;
    }

    .ModalContent {
      height: auto;
      max-width: 732px;
      padding: 24px;
      width: 100%;
    }
  }

  &.ModalAboCheckoutSuccess {

    .ModalPageContent {
      height: auto;
    }

    .ModalContent {
      max-height: 100%;
      max-width: 1060px;
      width: calc(100% - 32px);

      @media screen and ( $above-mobile-media ) {
        height: auto;
      }
    }
  }

  &.ModalAboCheckout {

    .ModalPageContent {
      height: calc(100% - 32px);
    }

    .ModalContent {
      max-height: 100%;
      max-width: 1060px;
      width: calc(100% - 32px);
    }
  }

  &.ModalQuaternary {
    position: absolute;

    .ModalContent {
      background: $greyLightestPlus;
      max-width: 431px;
      padding-bottom: 64px;
      transform: none;
      width: 90%;
    }

    .ModalBackdrop {
      background: $greyDarkest;
      opacity: .8;
    }

    &.Active {

      .ModalContent {
        top: 63px;
      }
    }
  }

  &.ModalQuinary {
    position: absolute;

    .ModalContent {
      max-width: 700px;
      transform: none;
      width: 90%;
    }

    .ModalBackdrop {
      background: $greyDarkest;
      opacity: .8;
    }

    &.Active {

      .ModalContent {
        top: 63px;
      }
    }
  }

  &.ModalSenary {

    .ModalContent {
      max-width: 609px;
      padding: 32px 25px 58px;
      width: 90%;
    }

    .ModalBackdrop {
      background: $greyDarkest;
      opacity: .8;
    }

    :global {

      #QA {
        margin: 40px auto 0;
      }
    }
  }

  &.ModalLightbox {

    .ModalContent {
      max-width: 740px;
      padding: 16px 16px 8px;
      width: 90%;

      @media screen and ( $custom-1080-media ) {
        max-width: 638px;
      }
    }

    .ModalClose {
      right: 14px;
      top: 24px;
      z-index: 2;

      &:before {
        font-size: 20px;
      }
    }

    .ModalBackdrop {
      background: $black;
      opacity: .1;
    }
  }

  &.ModalCentered {

    .ModalContent {
      background: $greyLightestPlus;
      max-height: none;
      max-width: 500px;
      width: 90%;
    }

    .ModalBackdrop {
      background: $greyDarkest;
      opacity: .8;
    }

    &.Active {

      .ModalContent {
        left: calc(-50vw + 50%);
        right: calc(-50vw + 50%);
      }
    }
  }
}

.ModalBackdrop {
  background: $greyDarkest;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: initial;
  position: absolute;
  top: 0;
  transition: all .3s $defaultBezier;
  width: 100%;
}

.ModalBackdropDisableClose {
  cursor: auto;
}

.ModalContent {
  background: $white;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  max-height: 90%;
  padding: 48px 24px;
  pointer-events: initial;
  position: relative;
  top: -100%;
  transform: translateY(-50%);
  transition: all .3s $defaultBezier;
  width: 700px;
  z-index: 1000;
}

.ModalPageContent {
  text-align: left;

  span, a {
    display: inline !important;
  }
}

.ModalClose {
  cursor: pointer;
  font-size: 10px;
  font-weight: $font-weight-l;
  height: initial;
  line-height: 18px;
  padding-right: 32px;
  position: absolute;
  right: 18px;
  text-transform: uppercase;
  top: 25px;
  width: initial;
  z-index: 2;

  &:before {
    @extend .icomoon-font;
    color: $purpleDarker;
    content: $iconX;
    display: block;
    font-size: 26px;
    position: absolute;
    right: 0;
    top: -4px;
    transition: all .3s $defaultBezier;
  }

  &:hover {

    &:before {
      transform: scale(0.94);
    }
  }
}

.ModalScrollableContent {

  .ModalContent {
    height: 1000px;
    overflow-y: hidden;
    padding-left: 25px;
    padding-right: 25px;
  }

  :global {

    .ps--active-y {

      :local {

        .ModalContentWrapper {
          margin: 0 20px 0 1px;
        }
      }
    }
  }
}

.ModalContentWrapper {
  max-height: 100%;
  overflow: auto;
}

.ModalBack {
  color: $greyDarkest;
  display: none;
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  letter-spacing: 0.5px;
  margin-bottom: 22px;
  margin-left: 23px;
  padding-left: 26px;
  position: relative;
  text-transform: uppercase;

  &:before {
    @extend .icomoon-font !optional;
    color: $purpleDarker;
    content: $iconChevronLeft;
    display: block;
    font-size: 28px;
    left: -7px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .ModalContent {
    width: 69.5%;
  }
}

@media screen and ( $custom-370-media ) {

  .Modal {

    &.ModalSecondary {

      :global {

        .title {
          max-width: 88%;
          text-align: left;
        }
      }
    }
  }
}

@media screen and ( $mobile-media ) {

  .ModalContent {
    width: calc(100% - 10px);
  }

  .ModalPageContent {

    ul, ol {
      padding-left: 30px;
    }
  }

  .Modal {

    &.ModalQuinary {

      .ModalContent {
        border: 0;
        border-radius: 0;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 18px;
        width: 100%;
      }

      .ModalClose {
        display: none;
      }

      &.Active {

        .ModalContent {
          top: -73px;
        }
      }

      .ModalBack {
        display: block;
      }
    }

    &.ModalSenary {

      .ModalContent {
        padding: 32px 17px 42px;
      }
    }
  }
}

@media print {

  .Modal {
    position: absolute;
    top: 0;

    &.ModalTertiary .ModalContent {
      height: unset;
      max-height: unset;
      position: absolute;
      top: 0;
    }
  }

  .ModalScrollableContent,
  .ModalContent {
    height: unset;
    max-height: unset;
    overflow-y: visible;
    transform: translateY(0);
  }

  :global {

    .ps {
      overflow: visible;
    }
  }
}
