/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ScrollToTopButton {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 50%;
  bottom: 315px;
  box-sizing: border-box;
  cursor: pointer;
  height: 48px;
  position: fixed;
  right: 32px;
  width: 48px;
  z-index: 4;

  &:before {
    @extend .icomoon-font !optional;
    color: $grey;
    content: $iconChevronUp;
    font-size: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s $defaultBezier;
  }

  &:hover {

    &:before {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .ScrollToTopButton {
    height: 40px;
    right: 20px;
    width: 40px;
  }
}

@media screen and ( $mobile-media ) {

  .ScrollToTopButton {
    bottom: 84px;
  }
}
