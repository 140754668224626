/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

@keyframes fadeInNotification {

  from {
    max-height: 300px;
    opacity: 1;
  }

  to {
    max-height: 0;
    opacity: 0;
  }
}

.Notification {
  color: $white;
  position: relative;

  p {
    padding: 35px 0;
  }
}

.NotificationSuccess {
  background: $asamGreenDarkest;
}

.NotificationError {
  background: $redError;
}

.NotificationWarning {
  background: $orange;
}

.NotificationInfo {
  background: $purple;
}

.NotificationClose {
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  transition: all .5s $defaultBezier;

  &:before {
    color: $purpleDarker;
    font-size: $fontSize-xxl;
  }

  &:hover {
    transform: translateY(-50%) scale(0.94);
  }
}

.NotificationAnimate {
  animation: fadeInNotification 1s forwards;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .Notification {

    p {
      padding: 16px 0;
    }
  }
}
