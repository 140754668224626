/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Footer {
  background: $greyLightestPlus;
  color: $greyDarkest;
  padding: 64px 0 36px;

  &.Secondary {
    padding: 48px 0 44px;
  }
}

.FooterGroupTitle {
  line-height: 22px;
  margin-bottom: 24px;
  text-transform: uppercase;

  span {
    position: relative;
  }
}

.FooterGroupTitleSecondary {
  margin-top: 24px;
}

.FooterLinks {
  display: flex;
  margin-bottom: 64px;

  > div:nth-child(3),
  > div:nth-child(6) {
    display: none;
  }
}

.FooterLinksListItem {
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.FooterColumn, .MultiselectGroup {
  margin-right: 2%;
  width: 23.5%;

  &:last-child {
    margin-right: 0;
  }
}

.FooterTrust {

  > img {
    max-height: 50px;
    max-width: 50px;
  }
}

.CarriersHolder {

  img {
    display: block;
    max-width: 100%;
  }
}

.CarrierItem {
  background-color: $white;
  box-shadow: 0 0 8px rgba(33, 34, 36, 0.2);
  width: 155px;

  & + .CarrierItem {
    margin-top: 8px;
  }
}

.TrustedShopsLogo {
  display: none;
  height: 24px;
  position: absolute;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
}

.FooterGroupContent {
  position: relative;
}

.PaymentIcon {
  position: relative;
}

.PaymentIconList {
  display: flex;
  flex-wrap: wrap;
  max-width: 167px;

  img {
    display: block;
    margin-bottom: 4px;
  }

  li {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.FooterAdditional {
  border-top: 1px solid $greyLightest;
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  padding-top: 12px;
}

.FooterAdditionalList {
  display: flex;
  flex-wrap: wrap;
  font-size: $fontSize-s;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;

  .Spacer {
    margin: 0 15px;
  }
}

.FooterAdditionalListItem {
  white-space: nowrap;

  &:before {
    content: '|';
    margin: 0 15px;
  }

  &:first-child {

    &:before {
      display: none;
    }
  }
}

.FooterAdditionalListCropped {
  display: flex;
}

.FooterSmallLogo {
  margin-top: 35px;

  img,
  svg {
    display: block;
    height: 43px;
    margin-left: auto;
    margin-right: auto;
    width: 48px;
  }
}

.SocialSection {
  margin-top: 30px;
}

.SocialSectionTitle {
  font-size: $fontSize-m;
  line-height: 22px;
  margin-bottom: 8px;
  text-align: center;
  text-transform: uppercase;
}

.SocialIconsList {
  display: flex;
  font-size: $fontSize-s;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
}

.AppSection {
  margin-top: 40px;
}

.AppIconsList {
  display: flex;
  justify-content: center;

  li {
    margin: 0 6px;
  }

  a {
    display: block;
  }

  img {
    display: block;
  }
}

.SocialIconsListItem {
  background: $grey;
  border-radius: 50%;
  cursor: pointer;
  height: 32px;
  margin: 0 6px;
  position: relative;
  transition: all .3s $defaultBezier;
  width: 32px;

  &:hover {
    opacity: 0.9;
  }

  a {
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-indent: -9999px;
    width: 100%;
  }

  &.Youtube {

    a {
      background: url('../../../../../../src/assets/images/sprites/stylesSprites.svg#socialYoutube') no-repeat center / 12px 14px;
    }
  }

  &.Facebook {

    a {
      background: url('../../../../../../src/assets/images/sprites/stylesSprites.svg#socialFacebook') no-repeat center / 8px 16px;
    }
  }

  &.Instagram {

    a {
      background: url('../../../../../../src/assets/images/sprites/stylesSprites.svg#socialInstagram') no-repeat center / 14px 14px;
    }
  }

  &.Pinterest {

    a {
      background: url('../../../../../../src/assets/images/sprites/stylesSprites.svg#socialPinterest') no-repeat center / 12px 14px;
    }
  }

  &.TikTok {

    a {
      background: url('../../../../../../src/assets/images/sprites/stylesSprites.svg#socialTikTok') no-repeat center / 14px 14px;
    }
  }
}

.Copyright {
  color: $greyDarker;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-top: 32px;
  padding: 0 24px;
  text-align: center;
}

.FooterInfo {
  color: $greyDarker;
  font-size: $fontSize-xs;
  line-height: 12px;
  margin-top: 47px;
  text-align: center;

  a {
    text-decoration: underline;
  }
}

.FooterImpressum {
  align-items: center;
  color: $grey;
  display: flex;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 656px;
  position: relative;

  .FooterImpressumLinks a {
    text-decoration: underline;
  }

  div {
    width: 45%;

    &:first-child {
      font-weight: $font-weight-l;
      padding-right: 15px;
      text-align: right;
    }

    &:last-child {
      border-left: 1px solid $greyLightest;
      padding-left: 15px;

      span {
        display: block;
      }
    }
  }

  .TrustedShopsLogo {
    display: block;
    height: 48px;
    max-width: none;
    position: absolute;
    right: 0;
    top: 50%;
    width: 48px;
  }
}

.FooterPaybackLogo {
  margin-left: 13px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .Footer {
    padding-top: 25px;

    > div {
      padding: 0;
    }

    &.Secondary {
      padding: 24px 0;
    }
  }

  .FooterColumn {
    width: 100%;
  }

  .FooterLinks {
    display: block;
    margin-bottom: 0;

    > div:nth-child(3),
    > div:nth-child(6) {
      display: block;
    }
  }

  .FooterLinksListItem {
    font-size: $fontSize-sm;
    font-weight: $font-weight-m;
    letter-spacing: 0.5px;
    line-height: 20px;
    padding: 13px 21px;
    position: relative;

    &:after {
      background: $greyDarkest;
      border-radius: 1px;
      content: '';
      height: 4px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
    }
  }

  .PaymentIconList {
    display: block;
    max-width: none;
    overflow: hidden;

    li {
      float: left;
      margin-right: 5px;
    }
  }

  .TrustedShopsLogo {
    display: block;
  }

  .FooterAdditional {
    border: 0;
    padding-top: 22px;
    position: relative;
  }

  .FooterAdditionalList {
    padding: 0 20px;
  }

  .FooterInfo {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and ( $mobile-media ) {

  .Footer {
    padding-top: 17px;
  }

  .FooterInfo {
    margin-top: 28px;
  }

  .FooterImpressum {
    margin: 0 30px 0 20px;
    max-width: 100%;

    div {

      &:first-child {
        width: auto;
      }
    }
  }

  .FooterAdditionalListCropped {
    display: block;
    position: relative !important;
    top: 0;

    li {

      &:before {
        display: none;
      }
    }

    .Spacer {
      display: none;
    }
  }
}

@media screen and ( $custom-460-media ) {

  .FooterAdditionalList {

    .Spacer {
      margin: 0 3px;
    }
  }

  .FooterAdditionalListItem {

    &:before {
      margin: 0 3px;
    }
  }
}

@media screen and ( $custom-350-media ) {

  .FooterAdditionalList {
    font-size: $fontSize-xs;
  }
}
