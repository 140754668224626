/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.PreFooter {
  display: flex;
  justify-content: center;
  padding: 16px 0;
}

.PreFooterIcon {
  align-items: center;
  display: flex;
  padding-left: 16px;
  width: 25%;

  img,
  svg {
    height: 48px;
    width: 48px;
  }
}

.PreFooterIconText {
  color: $grey;
  font-size: $fontSize-s;
  font-weight: $font-weight-l;
  line-height: 20px;
  margin-left: 16px;
  max-width: 100px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .PreFooter {
    justify-content: space-around;
    padding: 4px 0;
  }

  .PreFooterIcon {
    display: block;
    padding-left: 0;
    text-align: center;
  }

  .PreFooterIconText {
    margin-left: auto;
    margin-right: auto;
    max-width: 108px;
  }
}

@media screen and ( $mobile-media ) {

  .PreFooterIcon {
    width: 33%;

    &:last-child {
      display: none;
    }
  }
}

@media screen and ( $custom-370-media ) {

  .PreFooter {
    display: block;
  }

  .PreFooterIcon {
    margin: 10px 0;
    width: 100%;
  }
}
