/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CookieConsent {
  background: $white;
  border: 1px solid $greyLightest;
  bottom: 0;
  color: $greyDarker;
  font-family: $fontFamily;
  font-size: $fontSize-s;
  left: 0;
  padding: 24px 0;
  position: fixed;
  right: 0;
  z-index: 1000;

  .Content {
    display: flex;
    line-height: 22px;

    a {
      color: $purple;
      margin: 0 auto 0 15px;
      text-decoration: underline;
    }
  }

  button {
    background: $asamGreenDarker;
    border: 1px solid $asamGreenDarker;
    width: 160px;

    &:hover {
      background: $asamGreenDarkest;
      border: 1px solid $asamGreenDarkest;
    }
  }
}

/* ------------------------------------*\
  #RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .CookieConsent {
    padding-top: 16px;

    .Content {
      display: block;
      text-align: center;
      width: 100%;

      span {
        display: block;
      }

      a {
        margin: 0;
      }
    }

    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 24px;
      position: static;
      transform: none;
    }
  }
}
